import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { State } from "store";

interface LoginRouteProps {
  cmp: React.ReactNode;
}

const ProtectedRouteAuth = (props: LoginRouteProps): any => {
  const { cmp } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: State) => state.user);

  const validateparam = (id: any) => {
    if (!id?.match(/^\d+$/)) {
      check = true;
    } else {
      check = false;
    }
  };
  let check = false;

  if ("postID" in params) {
    const { postID } = params;
    validateparam(postID);
  } else if ("photoID" in params) {
    const { photoID } = params;
    validateparam(photoID);
  } else if ("messageID" in params) {
    const { messageID } = params;
    validateparam(messageID);
  } else if ("boxID" in params) {
    const { boxID } = params;
    validateparam(boxID);
  } else {
    const { userID } = params;
    validateparam(userID);
  }

  if (check) {
    return navigate("/404");
  } else if (isLoggedIn) {
    return cmp;
  } else {
    return navigate("/");
  }
};

export default ProtectedRouteAuth;
