import { CreatePostActionType } from "../action-type/type";
import { Action } from "../action/action";
import { BoxDataType } from "pages/contentPanel/types/types";

export interface showCreatePost {
  showCreatePost: boolean;
  selectedBox?: BoxDataType;
}

const initialShowCreatePost: showCreatePost = {
  showCreatePost: false,
};

const setShowCreatePost = (state: any, payload: showCreatePost) => {
  return (state = payload);
};

export const CreatePostReducer = (
  state = initialShowCreatePost,
  action: Action
) => {
  const { payload, type } = action;

  switch (type) {
    case CreatePostActionType.SET_SHOW_CREATE_POST:
      return setShowCreatePost(state, action.payload);
    default:
      return state;
  }
};

export default CreatePostReducer;
