import { useSelector } from "react-redux";
import { State } from "../store";

const Spinner = () => {
  const { isLoading } = useSelector((state: State) => state.loader);

  return (
    <>
      <div>
        <div
          className="loader-wrap fixed z-[999999] w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
          style={{
            display: isLoading ? "flex" : "none",
          }}
        >
          <div className="mosaic-loader1 w-[108px] flex flex-wrap relative justify-center items-center">
            <div className="cell d-0"></div>
            <div className="cell d-1"></div>
            <div className="cell d-2"></div>
            <div className="cell d-3"></div>
            <div className="cell d-1"></div>
            <div className="cell d-2"></div>
            <div className="cell d-3"></div>
            <div className="cell d-4"></div>
            <div className="cell d-2"></div>
            <div className="cell d-3"></div>
            <div className="cell d-4"></div>
            <div className="cell d-5"></div>
            <div className="cell d-3"></div>
            <div className="cell d-4"></div>
            <div className="cell d-5"></div>
            <div className="cell d-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
