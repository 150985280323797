import { ModalType } from "../action-type/type";
import { Action } from "../action/action";

export interface ModalState {
  displaySidebar: boolean;
  isDefaultModal?: boolean;
}

export const initialState: ModalState = {
  displaySidebar: true,
  isDefaultModal: false,
};

const ModalReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ModalType.SET_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case ModalType.SET_DEFAULT_MODAL:
      return {
        ...state,
        isDefaultModal: action.payload,
      };
    default:
      return state;
  }
};

export interface TabState {
  tabState: boolean;
  shareTabState: boolean;
}

export const tabInitialState: TabState = {
  tabState: false,
  shareTabState: false,
};

export const TabReducer = (state = tabInitialState, action: Action) => {
  switch (action.type) {
    case ModalType.SET_TAB_STATE:
      return {
        ...state,
        tabState: action.payload,
      };
    case ModalType.SET_SHARE_TAB_STATE:
      return {
        ...state,
        shareTabState: action.payload,
      };
    default:
      return state;
  }
};

export default ModalReducer;
