// store/timer/reducer/reducer.ts
import { TimerActionType } from "../action-type/type";
import { Action } from "../action/timerActions";

export interface TimeDuraion {
  duration?: number;
}

export interface TimeBoxAction {
  addABoxFunction?:string;
}

export interface TimeCartCount {
  cartCount?:number
}

interface TimeState {
  duration: TimeDuraion;
  boxAction: TimeBoxAction;
  cartCount: TimeCartCount;
}

const initialState: TimeState = {
  duration: { duration: 0 },
  boxAction: { addABoxFunction: "" },
  cartCount: { cartCount: 0 }
};

const timeReducer = (state: TimeState = initialState, action: Action): TimeState => {
  switch (action.type) {
    case TimerActionType.SET_ADD_ACTION:
      return {
        ...state,
        boxAction: action.payload
      };

    case TimerActionType.REMOVE_ADD_ACTION:
      return {
        ...state,
        boxAction: { addABoxFunction: "" }
      };

    case TimerActionType.SET_DURATION:
      return {
        ...state,
        duration: action.payload
      };

    case TimerActionType.REMOVE_DURATION:
      return {
        ...state,
        duration: { duration: 0 }
      };

    case TimerActionType.SET_CART_COUNT:
      return {
        ...state,
        cartCount: action.payload
      };

    case TimerActionType.REMOVE_CART_COUNT:
      return {
        ...state,
        cartCount: { cartCount: 0 }
      };

    default:
      return state;
  }
};

export default timeReducer;