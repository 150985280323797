export enum SidePanelType {
  SET_ALL_PHOTOS = "SET_ALL_PHOTOS",
  SET_ALL_POSTS = "SET_ALL_POSTS",
  SET_FAV_POSTS = "SET_FAV_POSTS",
  SET_FAV_DATA = "SET_FAV_DATA",
  SET_ALL_CONNECTIONS = "SET_ALL_CONNECTIONS",
  SET_ALL_MESSAGES = "SET_ALL_MESSAGES",
  SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS",
  SET_ALL_COMMENTS = "SET_ALL_COMMENTS",
  SET_ALLPHOTOS_LIKE = "SET_ALLPHOTOS_LIKE",
  SET_CONTENT_PANEL_DATA = "SET_CONTENT_PANEL_DATA",
  SET_SIDEPANELPAGE = "SET_SIDEPANELPAGE",
  SET_REMOVE_PHOTO = "SET_REMOVE_PHOTO",
  SET_USERPOST_LIKE = "SET_USERPOST_LIKE",
  SET_FAV_ALL_DATA = "SET_FAV_ALL_DATA",
  SET_UPDATE_USERFAV = "SET_UPDATE_USERFAV",
  SET_BADGE_PANELPAGE = "SET_BADGE_PANELPAGE",
  SET_TRANSACTIONS_PAGE = "SET_TRANSACTIONS_PAGE",
  SET_NEW_BOX_DATA = "SET_NEW_BOX_DATA",
  SET_REMOVE_POST = "SET_REMOVE_POST",
}
