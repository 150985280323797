import { ScrollEndActionType } from "../action-type/type";
import { Action } from "../action/action";

const initialScroll: Boolean = false;

const setScrollEnd = (state: any, payload: Boolean) => {
  return (state = payload);
};

export const ScrollEndReducer = (state = initialScroll, action: Action) => {
  const { payload, type } = action;

  switch (type) {
    case ScrollEndActionType.SET_SCROLL_END:
      return setScrollEnd(state, action.payload);
    default:
      return state;
  }
};

export default ScrollEndReducer;
