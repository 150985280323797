

export enum TimerActionType{
    SET_ADD_ACTION = "SET_ADD_ACTION",
    REMOVE_ADD_ACTION = "REMOVE_ADD_ACTION",
    SET_DURATION = "SET_DURATION",
    REMOVE_DURATION = "REMOVE_DURATION",
    SET_CART_COUNT = "SET_CART_COUNT",
    REMOVE_CART_COUNT = "REMOVE_CART_COUNT",
}
