import React from "react";

import Spinner from "./components/Spinner";
import RouteComponent from "./routes";
import HomePage from "pages/dashboard/HomePage";
import Toast from "./pages/dashboard/components/Toast";
import NewToast from "pages/NEW_dashboard/components/Toast";
import { Router, useRoutes } from "react-router-dom";
import routes from "../src/routes/routeFile";

function App() {
  const routeList = useRoutes(routes);
  return (
    <div className="main-container">
      <React.Suspense>
        <Spinner />

        {routeList}

        <Toast />
        {/* <NewToast /> */}
      </React.Suspense>
    </div>
  );
}

export default App;
