import React, { lazy, Suspense } from "react";

import PageNotFound from "../pages/404/PageNotFound";
import LoginRouteAuth from "./Auth/LoginRouteAuth";
import New404Page from "pages/NEW_404/404";
import ProtectedRouteAuth from "./Auth/ProtectedRouteAuth";
import UnProtectedRouteAuth from "./Auth/UnProtectedRouteAuth";
import { Outlet } from "react-router-dom";

enum ErrorType {
  HOMEPAGE = "HOMEPAGE",
  PHOTOS = "PHOTOS",
  SINGLEPHOTO = "SINGLEPHOTO",
  POST = "POST",
  SINGLEPOST = "SINGLEPOST",
  MESSAGE = "MESSAGE",
  SINGLEMESSAGE = "MESSAGE",
  BOX = "BOX",
  NOTIFICATIONS = "NOTIFICATIONS",
  ALLCONNECTIONS = "ALLCONNECTIONS",
  USERDATA = "USERDATA",
}

const Loadable = (Component: any) => {
  const Modified = (props: any) => (
    <Suspense fallback={<> </>}>
      <Component {...props} />
    </Suspense>
  );
  Modified.displayName = "Modified";
  return Modified;
};

const Activation = Loadable(
  lazy(() => import("../pages/auth/activation/Activation"))
);

const NewHomePage = Loadable(
  lazy(() => import("pages/NEW_dashboard/HomePage"))
);

const NewContact = Loadable(
  lazy(() => import("pages/NEW_dashboard/ContactPage"))
);

const NewAboutPage = Loadable(
  lazy(() => import("pages/NEW_dashboard/AboutPage"))
);

const NewFeed = Loadable(lazy(() => import("pages/NEW_dashboard/Feed")));

const NewMojo = React.lazy(() => import("pages/NEW_dashboard/Mojo"));

const NewNewsAndPress = Loadable(
  lazy(() => import("pages/NEW_dashboard/NewsAndPress"))
);

const NewFAQs = Loadable(lazy(() => import("pages/NEW_dashboard/FAQs")));

const NewPrivacyPolicy = Loadable(
  lazy(() => import("pages/NEW_dashboard/PrivacyPolicy"))
);

const NewTerms = Loadable(lazy(() => import("pages/NEW_dashboard/Terms")));

const NewBoxoverlay = Loadable(
  lazy(() => import("pages/NEW_contentPanel/QuickpackSolution"))
);

const NewMasterPanel = Loadable(
  lazy(() => import("pages/NEW_contentPanel/MasterPanel"))
);

const NewCreatePost = Loadable(
  lazy(() => import("pages/NEW_addapost/AddAPost"))
);

const UpdateProfiles = Loadable(
  lazy(() => import("pages/NEW_auth/UpdateProfile"))
);

const ConnectionPopup = Loadable(
  lazy(
    () => import("../pages/contentPanel/components/MyConnectionsOverlayPopup")
  )
);

const Boxes = Loadable(
  lazy(() => import("../pages/contentPanel/components/BoxsPanal"))
);

const NewLogin = Loadable(lazy(() => import("pages/NEW_auth/LoginPage")));

const NewSignup = Loadable(lazy(() => import("pages/NEW_auth/SignupPage")));

const NewResetPassword = Loadable(
  lazy(() => import("pages/NEW_auth/ResetPassword"))
);

const NewForgotPassword = Loadable(
  lazy(() => import("pages/NEW_auth/ForgotPassword"))
);

const DetailPanel = Loadable(
  lazy(() => import("pages/NEW_contentPanel/DetailPanel"))
);

const MessageDetail = Loadable(
  lazy(() => import("pages/NEW_contentPanel/MessageDetail"))
);

const NewsDetailPanel = Loadable(
  lazy(
    () => import("pages/NEW_dashboard/NewsAndPress/components/NewsDetailPanel")
  )
);

const ErrorBoundary = (source: any) => {
  // Uncaught ReferenceError: path is not defined
  switch (source.source) {
    case ErrorType.POST:
      return <div>This person hasn't posted anything yet!</div>;
    case ErrorType.PHOTOS:
      return <div>This person hasn't posted any photos yet!</div>;
    case ErrorType.SINGLEMESSAGE:
      return <div>Slow Internet! Please refresh page a few times.</div>;
    default:
      return <PageNotFound />;
  }
};

const routes = [
  {
    path: "/",
    element: <NewHomePage />,
    children: [
      {
        path: "/createPost",
        element: (
          <LoginRouteAuth>
            <NewCreatePost />
          </LoginRouteAuth>
        ),
      },
      {
        path: "/ref/:refID",
        element: (
          <LoginRouteAuth>
            <NewHomePage />
          </LoginRouteAuth>
        ),
      },
      {
        path: "/updateProfile",
        element: (
          <LoginRouteAuth>
            <UpdateProfiles />
          </LoginRouteAuth>
        ),
      },
      {
        path: "/:customUrl",
        element: <UnProtectedRouteAuth cmp1={<NewBoxoverlay />} />,

        errorElement: <ErrorBoundary source={ErrorType.BOX} />,
        children: [
          {
            path: "allBox",
            element: <UnProtectedRouteAuth cmp1={<Boxes />} />,
            errorElement: <ErrorBoundary source={ErrorType.BOX} />,
          },

          {
            path: "photos/:photoID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },

          {
            path: "posts/:postID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },

          {
            path: "messages/:messageID",

            element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "connection",

            children: [
              {
                path: ":connectionID",
                element: <UnProtectedRouteAuth cmp1={<ConnectionPopup />} />,
                errorElement: <ErrorBoundary source={ErrorType.SINGLEPOST} />,
              },
            ],
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
        ],
      },
      {
        path: "/box/:boxID",
        element: <UnProtectedRouteAuth cmp1={<NewBoxoverlay />} />,

        errorElement: <ErrorBoundary source={ErrorType.BOX} />,
        children: [
          {
            path: "allBox",
            element: <UnProtectedRouteAuth cmp1={<Boxes />} />,
            errorElement: <ErrorBoundary source={ErrorType.BOX} />,
          },
          {
            path: "photos/:photoID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },
          {
            path: "posts/:postID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "messages/:messageID",

            element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,

            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "connection",
            children: [
              {
                path: ":connectionID",
                element: <UnProtectedRouteAuth cmp1={<ConnectionPopup />} />,
                errorElement: <ErrorBoundary source={ErrorType.SINGLEPOST} />,
              },
            ],
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
        ],
      },
      {
        path: "/user/:userID",
        element: <ProtectedRouteAuth cmp={<NewMasterPanel />} />,
        errorElement: <ErrorBoundary source={ErrorType.USERDATA} />,
        children: [
          {
            path: "myAllPhotos",
            element: <Outlet />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },
          {
            path: "myAllPhotos/:photoID",
            element: <ProtectedRouteAuth cmp={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },
          {
            path: "myMessages",
            element: <Outlet />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "myMessages/:messageID",
            element: <ProtectedRouteAuth cmp={<MessageDetail />} />,
            errorElement: <ErrorBoundary source={ErrorType.SINGLEPOST} />,
          },

          {
            path: "myAllPosts",
            element: <Outlet />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "myAllPosts/:postID",
            element: <ProtectedRouteAuth cmp={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "connection",
            element: <Outlet />,

            errorElement: <ErrorBoundary source={ErrorType.POST} />,
            children: [
              {
                path: ":connectionID",
                element: <ProtectedRouteAuth cmp={<ConnectionPopup />} />,
                errorElement: <ErrorBoundary source={ErrorType.SINGLEPOST} />,
              },
            ],
          },
          {
            path: "myBoxes",
            element: <Outlet />,
            errorElement: <ErrorBoundary source={ErrorType.BOX} />,
          },

          {
            path: "myFavPosts",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "notifications",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "myRewards",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "myBadges",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "myTransactions",
            element: <Outlet />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
  {
    path: "/contact",
    element: <NewContact />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/about",
    element: <NewAboutPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/feed",
    element: <NewFeed />,
    children: [
      {
        path: ":customUrl/posts/:postID",
        element: <ProtectedRouteAuth cmp={<DetailPanel />} />,
        errorElement: <ErrorBoundary source={ErrorType.POST} />,
      },
      {
        path: ":customUrl/messages/:messageID",
        element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,
        errorElement: <ErrorBoundary source={ErrorType.POST} />,
      },
      {
        path: "box/:boxID/posts/:postID",
        element: <ProtectedRouteAuth cmp={<DetailPanel />} />,
        errorElement: <ErrorBoundary source={ErrorType.POST} />,
      },
      {
        path: "box/:boxID/messages/:messageID",

        element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,

        errorElement: <ErrorBoundary source={ErrorType.POST} />,
      },
    ],
  },
  {
    path: "/about",
    element: <NewAboutPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/mojomiles",
    element: <NewMojo />,
    children: [
      {
        path: ":customUrl",
        element: <UnProtectedRouteAuth cmp1={<NewBoxoverlay />} />,
        errorElement: <ErrorBoundary source={ErrorType.BOX} />,
        children: [
          {
            path: "photos/:photoID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },
          {
            path: "posts/:postID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "messages/:messageID",

            element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
        ],
      },
      {
        path: "box/:boxID",
        element: <UnProtectedRouteAuth cmp1={<NewBoxoverlay />} />,
        errorElement: <ErrorBoundary source={ErrorType.BOX} />,
        children: [
          {
            path: "photos/:photoID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.PHOTOS} />,
          },
          {
            path: "posts/:postID",
            element: <UnProtectedRouteAuth cmp1={<DetailPanel />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
          {
            path: "messages/:messageID",
            element: <UnProtectedRouteAuth cmp1={<MessageDetail />} />,
            errorElement: <ErrorBoundary source={ErrorType.POST} />,
          },
        ],
      },
    ],
  },
  {
    path: "/news-press",
    element: <NewNewsAndPress />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/news-press/:newsID",
    element: <UnProtectedRouteAuth cmp1={<NewsDetailPanel />} />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/FAQs",
    element: <NewFAQs />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/privacy-policy",
    element: <NewPrivacyPolicy />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/terms",
    element: <NewTerms />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/404",
    element: <New404Page />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "*",
    element: <New404Page />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/activation/:uid/:token",
    element: <Activation />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/reset-password/:uid/:token",
    element: <NewResetPassword />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <NewLogin />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/signup",
    element: <NewSignup />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/forgot-password",
    element: <NewForgotPassword />,
    errorElement: <ErrorBoundary />,
  },
];

export default routes;
