
export interface Props {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
}

export interface GetMoreBoxProps {
  setGetMoreBox: (flag: boolean) => void;
  className?: String;
}

export interface GetAlertBoxProps {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  className?: string;
  mapRefer: any | null;
}

export interface GetABoxProps {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  locationOccupied?: boolean;
  setLocationOccupied: (flag: boolean) => void;
  setBoxSize: (size: string) => void;
  setNeedZoomIn?: (flag: boolean) => void;
  setMoreInfo: (flag: boolean) => void;
  boxType: number;
  setBoxType: (flag: number) => void;
  isSmallBoxOverlap?: boolean;
  setIsSmallBoxOverlap: (flag: boolean) => void;
  className?: String;
}

export interface SecureBoxProps {
  setAuth?: (name: string) => void;
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  setShowSidebar?: (flag: boolean) => void;
  setFullView?: (flag: boolean) => void;
  setIsLoginPopUp?: (flag: boolean) => void;
  className?: String;
}

export interface CartProps {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  className?: String;
}

export interface CongratulationProps {
  id?: number;
  boxID: string;
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  setPostShowSidebar?: (flag: boolean) => void;
  isSmallBoxOverlap: boolean;
  isLast: boolean;
  setIndex: (index: number) => void;
  index: number;
  setIsSmallBoxOverlap: (flag: boolean) => void;
  className?: String;
}

export interface PositionYourBoxProps {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  boxType: number;
  locationOccupied: boolean;
  setIsSmallBoxOverlap: (flag: boolean) => void;
  className?: String;
}

export interface CheckoutProps {
  // box: CartDataType;
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  isLast: boolean;
  setBoxID: (flag: string) => void;
  initvalue: CartDataType;
  setIndex: (index: number) => void;
  index: number;
  className?: String;
}

export interface ExtraBoxesProps {
  setAddBox: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setBoxMarker: (flag: boolean) => void;
  className?: String;
}

export interface EditBoxProps {
  setBoxID: (flag: string) => void;
  setAddBox: (flag: boolean) => void;
  setBoxMarker: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  setIsSmallBoxOverlap: (flag: boolean) => void;
  isSmallBoxOverlap: boolean;
  className?: String;
}

export interface AddToCartProps {
  user_id: number;
  box_type: number;
  box_name: string;
  latitude: number | undefined;
  longitude: number | undefined;
  pay_amount?: any;
}

export interface DeletCartItem {
  user: number;
}

export interface BoxType {
  id: number;
  type: string;
  price: number;
}

export interface CartDataType {
  id: number;
  box_name: string;
  box_type: BoxType;
  user_id: number;
  latitude: number;
  longitude: number;
}

export interface CartItem {
  id: number;
  box_type: BoxTypeData;
  promo_code?: string | null;
  box_name: string;
  box_description?: string;
  latitude?: string;
  longitude?: string;
  pay_amount?: number | null;
  user_id: number;
}

export interface CartDataProps {
  box: CartItem;
  deleteHandler: (id: number) => void;
}

export interface BoxTypeData {
  type: string;
  price: number;
  id: number;
}

export interface CartDataApiResponse {
  data: CartItem[] | [];
  status: number;
  message: string;
  success: boolean;
}

export interface AddNewBox {
  item_id: number;
  user_id: number;
  box_name: string;
  box_image?: Blob | string;
  box_background_image?: Blob | string;
  type: number;
  latitude: number;
  longitude: number;
}
export interface BoxPostFormValues {
  images?: any;
  title?: string;
  description?: string;
  anylink?: string;
  youtubeurl?: string;
  credit?: string;
  select_box?: string;
  allBox?: string;
  user_id: number;
  item_id: number;
  box_name: string;
  box_image?: Blob | string;
  box_background_image?: Blob | string;
  type: number;
  latitude: number;
  longitude: number;
}

export interface EditNewBoxProps {
  initvalue: CartItem;
  setBoxID: (flag: string) => void;
  setAddBox: (flag: boolean) => void;
  setIndex: (index: number) => void;
  setBoxMarker: (flag: boolean) => void;
  setAddABoxFunction: (fun: string) => void;
  index: number;
  isLast: boolean;
  setIsSmallBoxOverlap: (flag: boolean) => void;
  isSmallBoxOverlap?: boolean;
}

export interface BoxSignupFormValues {
  box_name: string;
  name: string;
  email: string;
  password: string;
  confirm_password: string;
}

export interface MoreInfoProps {
  setMoreInfo: (flag: boolean) => void;
}

export enum GetABoxType {
  GET_ALERT_BOX = "GET_ALERT_BOX",
  GET_A_BOX = "GET_A_BOX",
  POSITIONYOURBOX = "POSITIONYOURBOX",
  SIGN_UP_SECURE_BOX = "SIGN_UP_SECURE_BOX",
  GET_MORE_BOX = "GET_MORE_BOX",
  SECURE_BOX = "SECURE_BOX",
  EXTRA_BOX = "EXTRA_BOX",
  CART = "CART",
  CHECKOUT = "CHECKOUT",
  CONGRATULATIONS = "CONGRATULATIONS",
  EDIT_BOX = "EDIT_BOX",
  LOGIN = "LOGIN",
}

export enum BoxesType {
  SMALL = "SMALL",
  STANDARD = "STANDARD",
  LARGE = "LARGE",
}

export enum MapType {
  DEFAULT = "DEFAULT",
  SATELLITE = "SATELLITE",
}

export enum BoxOverlayType {
  DEFAULT = "DEFAULT",
  BOXES = "BOXES",
  POST = "POST",
  PHOTOS = "PHOTOS",
  MESSAGES = "MESSAGES",
  COMMENTS = "COMMENTS",
  CONNECTIONS = "CONNECTIONS",
  FOLLOWEDBY = "FOLLOWEDBY",
  FOLLOWING = "FOLLOWING",
}
