import { SidePanelType } from "../action-type/type";
import { Action } from "../action/action";
import {
  PhotosType,
  sendRequestTypes,
  messageType,
} from "pages/contentPanel/types/types";
import { SidePanelPage } from "../../../pages/contentPanel/types/types";
import { photolikeTypes } from "pages/contentPanel/components/PhotosPanel";
export interface SidePanelState {
  allData: any[];
  user_photos?: PhotosType[] | null;
  user_posts: any[];
  favalldatas: any[];
  user_boxes?: any[];

  favData: any[];

  connections: sendRequestTypes[] | [];
  notification: any[];
  user_message: messageType[] | null;
  connection: any[];
  sidePanelPage: String;
  photos: PhotosType[] | [];
  transactions: any[];
}

export const initialState: SidePanelState = {
  allData: [],
  user_photos: null,
  user_posts: [],
  favalldatas: [],

  favData: [],
  connections: [],
  connection: [],
  notification: [],
  user_message: [],
  sidePanelPage: SidePanelPage.DEFAULT,
  user_boxes: undefined,
  photos: [],
  transactions: [],
};
const updateUserPostLikes = (state: any, payload: any) => {
  const tempPosts = [...state.user_posts];
  const postIndex = tempPosts.findIndex(
    (post) => post.postID === payload.post_id
  );
  const updateLikeIndex = tempPosts[postIndex].likes?.findIndex(
    (like: any) => like.user === payload.user_id
  );

  if (updateLikeIndex !== -1) {
    if (payload.like === tempPosts[postIndex].likes[updateLikeIndex].like) {
      tempPosts[postIndex].likes = tempPosts[postIndex].likes?.filter(
        (like: any) => like.user !== payload.user_id
      );
    } else {
      tempPosts[postIndex].likes[updateLikeIndex].like = payload.like;
    }
  } else {
    const likesobj = {
      like: payload.like,
      post_id: payload.post_id,
      user: payload.user_id,
    };
    tempPosts[postIndex].likes = [...tempPosts[postIndex].likes, likesobj];
  }
  return {
    ...state,
    user_posts: tempPosts,
  };
};
const updateUserFavoriteData = (state: any, payload: any) => {
  const tempFav = [...state.favorite];
  const favIndex = tempFav?.findIndex(
    (fav: any) =>
      fav.fav_id === payload.fav_id && fav.favorite === payload.favorite
  );
  if (favIndex !== -1) {
    const updatedFav = tempFav?.filter(
      (fav: any) =>
        fav.fav_id !== payload.fav_id || fav.favorite !== payload.favorite
    );
    return {
      ...state,
      favorite: updatedFav,
    };
  } else {
    const updateFavData = {
      fav_id: payload.fav_id,
      favorite: payload.favorite,
      user: payload.user,
    };
    return {
      ...state,
      favorite: [...tempFav, updateFavData],
    };
  }
};

const updateAllFav = (state: any, payload: any) => {
  // const copyState = [...state.favData];
  const newState = payload;
  return {
    ...state,
    favData: newState,
  };
};

const SidePanelReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SidePanelType.SET_ALL_PHOTOS:
      return {
        ...state,
        photos: action.payload,
      };
    case SidePanelType.SET_ALL_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case SidePanelType.SET_FAV_DATA:
      return {
        ...state,
        favdatas: action.payload,
      };
    case SidePanelType.SET_FAV_ALL_DATA:
      return updateAllFav(state, action.payload);

    case SidePanelType.SET_FAV_POSTS:
      return {
        ...state,
        favPosts: action.payload,
      };
    case SidePanelType.SET_ALL_CONNECTIONS:
      return {
        ...state,
        connections: action.payload, //connection: action.payload,
      };
    case SidePanelType.SET_USERPOST_LIKE:
      return {
        ...state,
        user_posts: updateUserPostLikes(state.user_posts, action.payload),
      };
    case SidePanelType.SET_ALL_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case SidePanelType.SET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case SidePanelType.SET_CONTENT_PANEL_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case SidePanelType.SET_SIDEPANELPAGE:
      return {
        ...state,
        sidePanelPage: action.payload,
      };
    case SidePanelType.SET_BADGE_PANELPAGE:
      return {
        ...state,
        sidePanelPage: action.payload,
      };

    case SidePanelType.SET_TRANSACTIONS_PAGE:
      return {
        ...state,
        sidePanelPage: action.payload,
      };

    case SidePanelType.SET_NEW_BOX_DATA:
      return {
        ...state,
        user_boxes: [...(state.user_boxes ?? []), action.payload],
      };

    case SidePanelType.SET_USERPOST_LIKE:
      return updateUserPostLikes(state, action.payload);
    case SidePanelType.SET_UPDATE_USERFAV:
      return updateUserFavoriteData(state, action.payload);

    case SidePanelType.SET_REMOVE_PHOTO:
      const updatedPhotos = state.user_photos?.filter(
        (photo) => photo.photoID !== parseInt(action.payload.photoID)
      );
      const updatedPhotosInAllData = state.allData?.filter(
        (photo) => photo?.photoID !== parseInt(action.payload.photoID)
      );
      return {
        ...state,
        user_photos: updatedPhotos,
        allData: updatedPhotosInAllData,
      };

    case SidePanelType.SET_REMOVE_POST:
      const updatedPosts = state.user_posts?.filter(
        (post) => post.postID !== parseInt(action.payload.postID)
      );
      const updatedPostsInAllData = state.allData?.filter(
        (post) =>
          post?.postID !== parseInt(action.payload.postID) &&
          post?.post_id !== parseInt(action.payload.postID)
      );
      return {
        ...state,
        user_posts: updatedPosts,
        allData: updatedPostsInAllData,
      };
    default:
      return state;
  }
};

export default SidePanelReducer;
