export enum ActionType {
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  REGISTER_FAIL = "REGISTER_FAIL",
  LOGIN_FAIL = "LOGIN_FAIL",
  LOGOUT = "LOGOUT",
  SETUSER = "SETUSER",
  UPDATEUSER = "UPDATEUSER",
  UPDATEMAPVIEW = "UPDATEMAPVIEW"
}

