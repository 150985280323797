import { ActionType } from "../action-Types";
import { Action } from "../action/action";

export interface LatLngState {
    latitude?: string[]
    longitude?: string[]
    cartLatitude?: string[]
    cartLongitude?: string[]
}

export const initialValue = {
    latitude: [],
    longitude: [],
    cartLatitude: [],
    cartLongitude: []
}

const LatLngReducer = (state = initialValue, action: Action) => {
    switch (action.type) {
        case ActionType.SET_LAT_LNG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default LatLngReducer