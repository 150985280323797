import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { State } from "store";

interface LoginRouteProps {
  cmp1: React.ReactNode;
}

const UnProtectedRouteAuth = (props: LoginRouteProps): any => {
  const { cmp1 } = props;
  const params = useParams();
  const navigate = useNavigate();

  const validateparam = (id: any) => {
    if (!id?.match(/^\d+$/)) {
      check = true;
    } else {
      check = false;
    }
  };

  const validateCustomUrl = (customUrl?: string) => {
    if (!customUrl?.match(/^[^\s]+$/)) {
      check = true;
    } else {
      check = false;
    }
  };
  let check = false;

  if ("postID" in params) {
    const { postID } = params;
    validateparam(postID);
  } else if ("photoID" in params) {
    const { photoID } = params;
    validateparam(photoID);
  } else if ("messageID" in params) {
    const { messageID } = params;
    validateparam(messageID);
  } else if ("boxID" in params) {
    const { boxID } = params;
    validateparam(boxID);
  } else if ("newsID" in params) {
    const { newsID } = params;
    validateparam(newsID);
  } else {
    const { customUrl } = params;
    validateCustomUrl(customUrl);
  }

  if (check) {
    return navigate("/404");
  } else {
    return cmp1;
  }
};

export default UnProtectedRouteAuth;
