import { CartActionType } from "../action-type/type";
import { Action } from "../action/action";

export interface CartType {
  user_id: number;
  box_name?: string;
  box_type?: number | null;
  latitude?: number;
  longitude?: number;
  isPending?: boolean;
  pay_amount?:number;
  box_description?: string;
}

const initialCart: CartType[] = [];

const setCartValue = (state: any, payload: any) => {
  const cartData = [...state];
  cartData.push(payload);

  return (state = cartData);
};

const setAllCart = (state: any, payload: any) => {
  let cartAllData = [...state];
  cartAllData = payload;
  return (state = cartAllData);
};

const removeCartitem = (state: any, payload: any) => {
  let cartItemData = [...state];
  let cartdata = cartItemData.filter((item) => item.id !== payload);
  return (state = cartdata);
};

export const CartReducer = (state = initialCart, action: Action) => {
  const { payload, type } = action;
  switch (type) {
    case CartActionType.SET_CART:
      return setCartValue(state, action.payload);

    case CartActionType.SET_REMOVE_CART:
      return removeCartitem(state, action.payload);
    case CartActionType.SET_UPDATE_CART:
      return {
        ...state,
        ...payload,
      };

    case CartActionType.SET_ALL_CART:
      return setAllCart(state, action.payload);

    default:
      return state;
  }
};

export default CartReducer;
