export enum BoxActionType {
  SET_BOX = "SET_BOX",
  SET_CURRENT_BOXES = "SET_CURRENT_BOXES",
  SET_CURRENT_BOX_DATA = "SET_CURRENT_BOX_DATA",
  SET_PHOTOS = "SET_PHOTOS",
  SET_CONTENT_PANEL = "SET_CONTENT_PANEL",
  SET_UPDATE_LIKE = "SET_UPDATE_LIKE",
  SET_PHOTOS_LIKE = "SET_PHOTOS_LIKE",
  SET_UPDATE_FAV = "SET_UPDATE_FAV",
  SET_PHOTO_FAV = "SET_PHOTO_FAV",
  SET_ADD_COMMENT = "SET_ADD_COMMENT",
  SET_UPDATE_COMMENT = "SET_UPDATE_COMMENT",
  SET_REMOVE_PHOTO = "SET_REMOVE_PHOTO",
  SET_DELETE_PHOTOS = "SET_DELETE_PHOTOS",
  SET_DELETE_COMMENTS = "SET_DELETE_COMMENTS",
  SET_ALL_COMMENTS = "SET_ALL_COMMENTS",
  SET_UPDATE_BOX_NAME = "SET_UPDATE_BOX_NAME",
  SET_UPDATE_BOX_DESCRIPTION = "SET_UPDATE_BOX_DESCRIPTION",
  SET_ADD_SUBCOMMENT = "SET_ADD_SUBCOMMENT",
  SET_DELETE_SUBCOMMENTS = "SET_DELETE_SUBCOMMENTS",
  SET_ALL_SUBCOMMENTS = "SET_ALL_SUBCOMMENTS",
  SET_UPDATE_BOX_IMAGE = "SET_UPDATE_BOX_IMAGE",
  SET_UPDATE_BOX_BG_IMAGE = "SET_UPDATE_BOX_BG_IMAGE",
  SET_ALL_CONNECTION = "SET_ALL_CONNECTION",
}
