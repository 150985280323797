import {
  PhotosType,
  commentTypes,
  SubCommentDataType,
} from "../../../pages/contentPanel/types/types";
import { BoxDataType } from "../../../pages/contentPanel/types/types";
import { BoxOverlayType } from "../../../pages/getabox/types/BoxSignupType";
import { BoxActionType } from "../action-type/type";
import { Action } from "../action/action";

export interface BoxState {
  isBox: boolean;
  box_name?: string;
  box_type?: number | null;
  latitude?: number;
  longitude?: number;
}

export const initialBoxState: BoxState = {
  isBox: false,
  box_name: "",
  box_type: null,
  latitude: 0,
  longitude: 0,
};

const boxReducer = (state = initialBoxState, action: Action) => {
  const { payload, type } = action;
  switch (type) {
    case BoxActionType.SET_BOX:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export interface CurrentBoxState {
  allBoxes: any[];
  currentBox?: BoxDataType;
  posts: any[];
  photos?: PhotosType[] | null;
  boxes?: any;
  messages?: any[];
  connection?: any[];
  following?: any[];
  followedBy?: any[];
  comments?: commentTypes[];
  subcomments?: SubCommentDataType[];
  iindex?: number;
  activeSlider?: number;
  contentPanel: String;
}

const initialCurrentBoxState: CurrentBoxState = {
  allBoxes: [],
  contentPanel: BoxOverlayType.DEFAULT,
  photos: null,
  posts: [],
  comments: [],
  subcomments: [],
  connection: [],
  following: [],
  followedBy: [],
};

const updatePostLikes = (state: any, payload: any) => {
  const tempPosts = [...state.posts];
  const postIndex = tempPosts.findIndex(
    (post) => post.postID === payload.post_id
  );
  const updateLikeIndex = tempPosts[postIndex].likes?.findIndex(
    (like: any) => like.user === payload.user_id
  );

  if (updateLikeIndex !== -1) {
    if (payload.like === tempPosts[postIndex].likes[updateLikeIndex].like) {
      tempPosts[postIndex].likes = tempPosts[postIndex].likes?.filter(
        (like: any) => like.user !== payload.user_id
      );
      tempPosts[postIndex].totalLikes =
        tempPosts[postIndex].totalLikes - payload.like;
    } else {
      tempPosts[postIndex].likes[updateLikeIndex].like = payload.like;
      let total_likes = 0;
      tempPosts[postIndex].likes.map((likeee: any, i: number) => {
        if (i == updateLikeIndex) {
          total_likes += payload.like;
          return { ...likeee, like: payload.like };
        } else {
          total_likes += likeee.like;
          return likeee;
        }
      });
      tempPosts[postIndex].totalLikes = total_likes;
    }
  } else {
    const likesobj = {
      like: payload.like,
      post_id: payload.post_id,
      user: payload.user_id,
    };
    tempPosts[postIndex].likes = [...tempPosts[postIndex].likes, likesobj];
    tempPosts[postIndex].totalLikes =
      tempPosts[postIndex].totalLikes + payload.like;
  }
  return {
    ...state,
    posts: tempPosts,
  };
};

const updatePhotosLikes = (state: any, payload: any) => {
  const tempPhotos = [...state.photos];
  const photosIndex = tempPhotos.findIndex(
    (photos) => photos.photoID === payload.photo_id
  );
  const updateLikeIndex = tempPhotos[photosIndex].photolikes?.findIndex(
    (photolike: any) => photolike.user === payload.user_id
  );

  if (updateLikeIndex !== -1) {
    if (
      payload.photolike ===
      tempPhotos[photosIndex].photolikes[updateLikeIndex].photolike
    ) {
      tempPhotos[photosIndex].photolikes = tempPhotos[
        photosIndex
      ].photolikes?.filter(
        (photolike: any) => photolike.user !== payload.user_id
      );
      tempPhotos[photosIndex].totalLikes =
        tempPhotos[photosIndex].totalLikes - payload.photolike;
    } else {
      tempPhotos[photosIndex].photolikes[updateLikeIndex].photolike =
        payload.photolike;
      let total_likes = 0;
      tempPhotos[photosIndex].photolikes.map((likeee: any, i: number) => {
        if (i == updateLikeIndex) {
          total_likes += payload.photolike;
          return { ...likeee, like: payload.photolike };
        } else {
          total_likes += likeee.like;
          return likeee;
        }
      });
      tempPhotos[photosIndex].totalLikes = total_likes;
    }
  } else {
    const likesobj = {
      photolike: payload.photolike,
      photo_id: payload.photo_id,
      user: payload.user_id,
    };
    tempPhotos[photosIndex].photolikes = [
      ...tempPhotos[photosIndex].photolikes,
      likesobj,
    ];
    tempPhotos[photosIndex].totalLikes =
      tempPhotos[photosIndex].totalLikes + payload.photolike;
  }
  return {
    ...state,
    photos: tempPhotos,
  };
};

const updateFavoriteData = (state: any, payload: any) => {
  const tempFav = [...state.favorite];
  const favIndex = tempFav?.findIndex(
    (fav: any) =>
      fav.fav_id === payload.fav_id && fav.favorite === payload.favorite
  );
  if (favIndex !== -1) {
    const updatedFav = tempFav?.filter(
      (fav: any) =>
        fav.fav_id !== payload.fav_id || fav.favorite !== payload.favorite
    );
    return {
      ...state,
      favorite: updatedFav,
    };
  } else {
    const updateFavData = {
      fav_id: payload.fav_id,
      favorite: payload.favorite,
      user: payload.user,
    };
    return {
      ...state,
      favorite: [...tempFav, updateFavData],
    };
  }
};

const setUpdateComment = (state: any, payload: any) => {
  var tempPosts = [...state.comments];
  tempPosts.push(payload);
  return {
    ...state,
    comments: tempPosts,
  };
};

const setBoxName = (state: any, payload: any) => {
  return {
    ...state,
    currentBox: { ...state.currentBox, box_name: payload.box_name },
  };
};

const setBoxDescription = (state: any, payload: any) => {
  return {
    ...state,
    currentBox: {
      ...state.currentBox,
      box_description: payload.box_description,
    },
  };
};

const setaddSubComment = (state: any, payload: any) => {
  var dupState = [...state.subcomments];

  dupState.push(payload);

  return {
    ...state,
    subcomments: dupState,
  };
};

const setremoveComment = (state: any, payload: number) => {
  var dupState = [...state.subcomments];

  let data = dupState.filter((value) => value.id !== payload);

  return {
    ...state,
    subcomments: data,
  };
};

const setAllComments = (state: any, payload: any) => {
  var dupState = [...state.comments];
  dupState = payload;

  return {
    ...state,
    comments: dupState,
  };
};

const setBoxImage = (state: any, payload: any) => {
  return {
    ...state,
    currentBox: { ...state.currentBox, box_image: payload.box_image },
  };
};

const setBoxBgImage = (state: any, payload: any) => {
  return {
    ...state,
    currentBox: {
      ...state.currentBox,
      box_background_image: payload.box_background_image,
    },
  };
};

export const BoxOverlayReducer = (
  state = initialCurrentBoxState,
  action: Action
) => {
  const { payload, type } = action;
  switch (type) {
    case BoxActionType.SET_CURRENT_BOXES:
      return {
        ...state,
        allBoxes: payload,
      };
    case BoxActionType.SET_PHOTOS:
      return {
        ...state,
        ...payload,
      };
    case BoxActionType.SET_CURRENT_BOX_DATA:
      return {
        ...state,
        ...payload,
      };
    case BoxActionType.SET_CONTENT_PANEL:
      return {
        ...state,
        contentPanel: payload,
      };
    case BoxActionType.SET_REMOVE_PHOTO:
      const updatedPhotos = state.photos?.filter(
        (photo) => photo.photoID !== parseInt(action.payload.photoID)
      );

      return {
        ...state,
        photos: updatedPhotos,
      };
    case BoxActionType.SET_ADD_COMMENT:
      return setUpdateComment(state, action.payload);

    case BoxActionType.SET_DELETE_COMMENTS:
      const updatedComments = state.comments?.filter(
        (comment) => comment.id !== action.payload
      );

      return {
        ...state,
        comments: updatedComments,
      };

    case BoxActionType.SET_UPDATE_BOX_NAME:
      return setBoxName(state, action.payload);

    case BoxActionType.SET_UPDATE_BOX_DESCRIPTION:
      return setBoxDescription(state, action.payload);

    case BoxActionType.SET_ALL_COMMENTS:
      return setAllComments(state, action.payload);
    case BoxActionType.SET_UPDATE_LIKE:
      return updatePostLikes(state, action.payload);

    case BoxActionType.SET_PHOTOS_LIKE:
      return updatePhotosLikes(state, action.payload);

    case BoxActionType.SET_UPDATE_FAV:
      return updateFavoriteData(state, action.payload);

    case BoxActionType.SET_PHOTOS:
      return;

    case BoxActionType.SET_ADD_SUBCOMMENT:
      return setaddSubComment(state, action.payload);

    case BoxActionType.SET_ALL_SUBCOMMENTS:
      return {
        ...state,
        subcomments: action.payload,
      };

    case BoxActionType.SET_DELETE_SUBCOMMENTS:
      return setremoveComment(state, action.payload);

    case BoxActionType.SET_UPDATE_BOX_IMAGE:
      return setBoxImage(state, action.payload);

    case BoxActionType.SET_UPDATE_BOX_BG_IMAGE:
      return setBoxBgImage(state, action.payload);

    case BoxActionType.SET_ALL_CONNECTION:
      return {
        ...state,
        connection: action.payload,
      };

    default:
      return state;
  }
};

export default boxReducer;
