import { MapRef } from "react-map-gl";
import { MapType } from "../action-type/type";
import { Action } from "../action/action";
import { Map } from "mapbox-gl";

export interface MapState {
  MapViewNew?: String;
  MapView?: boolean;
  needZoomIn?: boolean;
  isMarkerClicked?: boolean;
  maprefer?: any | null;
}

export const initialMapState: MapState = {
  MapViewNew: "3D",
  MapView: true,
  needZoomIn: true,
  isMarkerClicked: false,
  maprefer: null,
};

const MapReducer = (state = initialMapState, action: Action) => {
  switch (action.type) {
    case MapType.SET_MAPVIEW:
      return {
        ...state,
        MapView: action.payload,
      };
    case MapType.SET_MAPVIEW_NEW:
      return {
        ...state,
        MapViewNew: action.payload,
      };
    case MapType.SET_NEED_OF_ZOOM_IN:
      return {
        ...state,
        ...action.payload,
      };

    case MapType.SET_ISMARKERCLICKED:
      return {
        ...state,
        ...action.payload,
      };
    case MapType.SET_MAPREFER:
      return {
        ...state,
        maprefer: action.payload,
      };
    default:
      return state;
  }
};

export default MapReducer;
