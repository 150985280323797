import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { State } from "store";

interface LoginRouteProps {
  children: ReactElement;
  type?: string;
}

const LoginRouteAuth: React.FC<LoginRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: State) => state.user);

  return <>{isLoggedIn ? children : navigate("/")}</>;
};

export default LoginRouteAuth;
